import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

interface Props {
    title?: string;
    description?: string;
    pathname?: string;
    children?: any;
}

export const SEO: React.FC<Props> = ({ title, description, pathname, children }) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        image,
        siteUrl,
        twitterUsername
    } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername,
    }

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:url" content={seo.url} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:image" content={seo.image} />
            <meta name="twitter:creator" content={seo.twitterUsername} />
            <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" />
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Bruce Crossing",
                            "addressRegion": "MI",
                            "postalCode": "49912",
                            "streetAddress": "14896 US-45"
                        },
                        "openingHours": "Mo-Su 11:00-17:00",
                        "paymentAccepted":"Cash, Credit Card",
                        "name": "Settlers Depot Gallery",
                        "telephone": "(906) 827-3940",
                        "url": "https://www.settlersdepotgallery.com",
                        "description": "Gifts, Art, Photography and Handcrafts Produced in Michigan's Upper Peninsula."
                    }       
                `}
            </script>
            {children}
        </>
    )
}