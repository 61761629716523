import * as React from "react"
import type { HeadFC, HeadProps } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import GlobalStyle from '../styles/globalStyle';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Gallery = styled.div`
display:grid;
grid-template-columns: repeat(3, 1fr);
gap: 10px;
max-width:38rem;
`

const IndexPage = () => {
  return (
    <><GlobalStyle />
      <Layout>
        <h2>Gifts, Art, Photography and Handcrafts Produced in Michigan's Upper Peninsula.</h2>

        <Gallery><StaticImage src="../images/ornament.jpg"
          alt=""
          placeholder="blurred"
          width={400}
          height={400}
        />
          <StaticImage src="../images/treeoflife.jpeg"
            alt=""
            placeholder="blurred"
            width={400}
            height={400}
          />
          <StaticImage src="../images/ragrug.jpg"
            alt=""
            placeholder="blurred"
            width={400}
            height={400}
          />
          <StaticImage src="../images/post.jpg"
            alt=""
            placeholder="blurred"
            width={400}
            height={400}
          />
          <StaticImage src="../images/towels.jpg"
            alt=""
            placeholder="blurred"
            width={400}
            height={400}
          />
          <StaticImage src="../images/paper.jpg"
            alt=""
            placeholder="blurred"
            width={400}
            height={400}
          />
        </Gallery>
        <h2>About Settlers Depot Gallery</h2>
        <p>Settlers Depot Gallery is an artisans' cooperative. Annually they have between 15 and 25 active members. All items in the store are made by the co-op members. Members also work the retail part of the business, maintain the historic structure, the old DSS&amp;A rail depot, and are active in community events. They are proud to be part of the community in Bruce Crossing and the Western Upper Peninsula of Michigan.</p>
        <StaticImage src="../images/SDG.svg"
          alt=""
          placeholder="blurred"
        />
      </Layout></>
  )
}

export default IndexPage

export const Head: HeadFC = () => {
  return (
    <>
      <SEO />
    </>
  );
};