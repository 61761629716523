import * as React from 'react'
import styled from "styled-components"

const Head = styled.header`
    min-width: 16rem;
    background: #f9f9f9;
    border-right: 1px solid #f4f4f4;
    padding-left: 2rem;
    padding-right: 2rem;
    @media (max-width: 768px) {
        text-align: center;
        padding: 2rem;
        border-bottom: 1px solid #f4f4f4;
        border-right: unset;
 
    }
`

const H1 = styled.h1`
    color: #222;
    text-transform:uppercase;
    margin: 0 auto;
    
    @media (max-width: 768px) {
        width: 12rem;
    }
`
const Frame = styled.div`
    border: 5px solid #444;
    margin: 2rem auto;
    
   
    @media (max-width: 768px) {
        width: 14rem;
    }

`

export const Header: React.FC = () => {
    return (
        <Head>
            <Frame><H1>Settlers<br />Depot<br />Gallery</H1></Frame>
            <p><a href="https://goo.gl/maps/WPm24q882XSc7k7q9" target="_blank">14896 US-45 <br />Bruce Crossing, MI 49912</a></p>
            <p>(906) 827-3940</p>
            <p>Open daily 11 a.m. - 5 p.m. <br />June through September.</p>
            <p>Call for hours <br />Oct. through December.</p>
        </Head>
    )
}