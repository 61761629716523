import * as React from 'react'
import { Link } from 'gatsby'
import { Header } from './header'
import { Footer } from './footer'
import styled from "styled-components"

interface Props {
    children?: any;
}

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: clip;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        overflow: unset;
    }
`

const Main = styled.main`
    padding: 0 2rem;
    max-width: 40rem;
    margin: 2rem auto;
`
const NotHeader = styled.div`
    flex-grow: 3;
    overflow-y: scroll;
`

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <Container>
            <Header />
            <NotHeader>
                <Main>
                    {children}
                </Main>
                <Footer />
            </NotHeader>
        </Container>
    )
}

export default Layout