import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
    *{
        box-sizing:border-box;
        font-family: Raleway, Arial, sans-serif; 
    }

    div, header, body{
        margin: 0;
        padding: 0;
        outline:0;
    }

    body{
        min-height: 100vh;
    }
    
    #root{
        margin:0 auto;
        min-height: 100vh;
    }

    h1, h2, h3, h4{
        font-family: 'Comfortaa', sans-serif;
    }

    h2{
        border-bottom: 1px solid #555;
        padding-bottom: 0.5rem;
        margin-top: 2rem;
    }
 `