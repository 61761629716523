import * as React from 'react'
import styled from "styled-components"

const Foot = styled.footer`
    width: 100%;
    background: #fff;
    border-top: 1px solid #eee;
    padding: 1rem;
    text-align: center;
`

export const Footer: React.FC = () => {
    return (
        <Foot>
            <p>This is an unofficial fan website. <a href="https://www.facebook.com/Settlers-Depot-Gallery-157330394402267/">Contact Settlers Depot Gallery on Facebook</a>.</p>
        </Foot>
    )
}