import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata: React.FC = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `)

    return data.site.siteMetadata
}